import { useState, useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Stack, Box, Container } from '@mui/material';
import UserImage from '../../components/UserImage';
import { UserContext } from '../../context/UserContext';
import Authorizer from '../../utils/Authorizer';
import UserInfo from './components/UserInfo';
import HorizontalLinearStepper from './components/HorizontalLinearStepper';
import ManualReview from './components/ManualReview';
import ReviewRecord from '../../components/ReviewRecord';
import { RiskReviewContext } from './context/RiskReviewContext';

const stepMappingTable = { 0: 'kyc', 1: 'aml' };

export default function RiskReviewItem() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setNavTitle, setSubNavTitle } = useContext(UserContext);
  const [activeStep, setActiveStep] = useState(0);

  const value = useMemo(
    () => ({
      identityVerificationId: state.orderNumber,
      kycReviewComment: '',
      amlReviewComment: '',
      kycReviewResult: state.disableKyc ? null : 'staff_approved',
      amlReviewResult: state.disableAml ? null : 'staff_approved',
    }),
    [state.orderNumber],
  );

  useEffect(() => {
    // 此頁是要從 /customerService 才能轉導過來的，所以如果沒有 state 就導回去
    if (state) {
      setSubNavTitle(`${state.name?state.name:""}(${state.memberId})`);
    } else {
      navigate('/riskControl');
      setNavTitle('風控審查');
    }
  }, [navigate, setNavTitle, state, setSubNavTitle]);

  return (
    <RiskReviewContext.Provider value={value}>
      <Container
        maxWidth={'md'}
        sx={{ marginTop: '40px', marginBottom: '40px' }}>
        {state && (
          <HorizontalLinearStepper
            activeStep={activeStep}
            setActiveStep={setActiveStep}>
            <Stack direction="column" spacing={4}>
              <UserInfo userData={state} type={stepMappingTable[activeStep]} />
              <UserImage
                orderNumber={state.orderNumber}
                value={activeStep}
                hiddenUploadBtn={true}
                DisableTabSwitch={true}
              />
              {stepMappingTable[activeStep] === 'kyc' && !state.disableKyc && (
                <ManualReview type="kyc" />
              )}
              {stepMappingTable[activeStep] === 'aml' && !state.disableAml && (
                <ManualReview type="aml" />
              )}
            </Stack>
          </HorizontalLinearStepper>
        )}
        <Box sx={{ marginTop: '40px' }}>
          <ReviewRecord orderNumber={state.orderNumber} />
        </Box>
      </Container>
    </RiskReviewContext.Provider>
  );
}
