import { Grid } from '@mui/material';
import { TitleLabel, SubTitleLabel } from './Label';

export default function CustomGrid({ data, underline, padding }) {
  return (
    <Grid container>
      {data.map((item, index) => {
        return (
          <Grid
            item
            key={index}
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              borderBottom: underline
                ? index === data.length - 1
                  ? ''
                  : '1px solid lightgrey'
                : '',
              padding: padding ? `${padding} 0` : '15px 0',
            }}>
            <TitleLabel fontSize="15px">{item.title}</TitleLabel>
            <SubTitleLabel>{item.value}</SubTitleLabel>
          </Grid>
        );
      })}
    </Grid>
  );
}
