import { Snackbar, Alert } from '@mui/material';

export default function AlertMessage({ alertState }) {
  const { alert, setAlert, severity } = alertState;

  const handleClose = () => {
    setAlert('');
  };

  return (
    <Snackbar
      open={alert === '' ? false : true}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert
        onClose={handleClose}
        severity={severity ? severity : 'error'}
        variant="filled"
        sx={{ width: '100%' }}>
        {alert}
      </Alert>
    </Snackbar>
  );
}
