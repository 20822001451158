import { useState } from 'react';
import { Box, Modal } from '@mui/material';
import WatermarkComponent from '../components/WatermarkComponent';

const modalStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%', // 佔全螢幕的比例
};

export default function ImageModal({ children, url, description }) {

  const [open, setOpen] = useState(false);
  const [scale, setScale] = useState(1); // 縮放比例，初始為 1
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setScale(1)
  }
  const handleWheel = (event) => {
    event.preventDefault();
    const scaleChange = event.deltaY > 0 ? 0.9 : 1.1; // 滾輪向下滾動縮小，向上滾動放大
    setScale(prevScale => prevScale * scaleChange);
  };

  return (
    <div>
      <Box onClick={handleOpen}>{children}</Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={
          { 
            overflow: 'auto',
          }
        }
      >
        <Box sx={modalStyle}>
          <WatermarkComponent style={{
            transform: `scale(${scale})`, // 使用縮放比例調整圖片大小
            transformOrigin: '0 0', // 設置縮放的原點為左上角
          }}>
            <img
              src={url}
              alt={`${description}`}
              loading="lazy"
              style={{
                height: '80vh',
                width: '100%',
                objectFit: 'contain',
              }}
              onWheel={handleWheel} // 監聽滾輪事件
            />
          </WatermarkComponent>
        </Box>
      </Modal>
    </div>
  );
}

