import { useMemo, useState } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/roboto';
import { UserContext } from './context/UserContext';
import Layout from './components/Layout';
import Loading from './components/Loading';
import Home from './pages/Home';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ResetPassword from './pages/ResetPassword';
import Accounting from './pages/Accounting';
import PushNotificationService from './pages/PushNotificationService';
import CustomerService from './pages/CustomerService';
import UserManagement from './pages/UserManagement';
import CustomerInfo from './pages/CustomerInfo';
import TradeList from './pages/TradeList';
import RiskReview from './pages/RiskReview';
import RiskReviewItem from './pages/RiskReviewItem';
import ScrollToTop from './components/ScrollToTop';
// import WatermarkComponent from './components/WatermarkComponent';
import RiskReviewConfirm from './pages/RiskReviewConfirm';
import RiskReviewConfirmItem from './pages/RiskReviewConfirmItem';
import OTP from './pages/OTP';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF8E15',
      contrastText: '#fff',
    },
    secondary: {
      main: '#6C757D',
    },
    background: {
      //default: "#FF00FFFF"
      // default: "#F8F9FA",
      default: '#fff',
    },
    text: {
      primary: '#070707',
      secondary: '#6C757D',
    },
    info: {
      main: '#fff',
    },
  },
  typography: {
    fontFamily: 'roboto',
    fontWeightLight: 100,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 900,
  },
});

function App() {
  const [navTitle, setNavTitle] = useState('');
  const [subNavTitle, setSubNavTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [availablePages, setAvailablePages] = useState();

  const value = useMemo(
    () => ({
      navTitle,
      setNavTitle,
      subNavTitle,
      setSubNavTitle,
      loading,
      setLoading,
      user,
      setUser,
      availablePages,
      setAvailablePages,
    }),
    [navTitle, subNavTitle, loading, user],
  );

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <UserContext.Provider value={value}>
          <CssBaseline />
          <Layout>
            {/* <WatermarkComponent> */}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/otp" element={<OTP />} />
              <Route path="/signUp" element={<SignUp />} />
              <Route path="/register" element={<SignUp />} />
              {/* email導頁之url為register */}
              <Route path="/resetPassword" element={<ResetPassword />} />
              <Route path="/customerService" element={<CustomerService />} />
              <Route
                path="/customerService/customerInfo"
                element={<CustomerInfo />}
              />
              <Route path="/tradeList" element={<TradeList />} />
              <Route path="/accounting" element={<Accounting />} />
              <Route path="/riskReview" element={<RiskReview />} />
              <Route path="/riskReview/:id/kyc" element={<RiskReviewItem />} />
              <Route path="/riskReview/:id/aml" element={<RiskReviewItem />} />
              <Route
                path="/riskReviewConfirm"
                element={<RiskReviewConfirm />}
              />
              <Route
                path="/riskReviewConfirm/:id/kyc"
                element={<RiskReviewConfirmItem />}
              />
              <Route
                path="/riskReviewConfirm/:id/aml"
                element={<RiskReviewConfirmItem />}
              />
              <Route
                path="/pushNotificationService"
                element={<PushNotificationService />}
              />
              <Route path="/userManagement" element={<UserManagement />} />
            </Routes>
            {/* </WatermarkComponent> */}
          </Layout>

          {/* Loading */}
          <Loading enabled={loading} />
        </UserContext.Provider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
