import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { UserContext } from '../../../context/UserContext';
import { adminsAPI } from '../../../apis/adminsAPI';
import Selector from '../../../components/Selector';

const groupItems = [
  { label: 'Super Admin', value: 'super_admin' },
  { label: 'Content Management', value: 'content_management' },
  { label: 'Customer Service', value: 'customer_service' },
  { label: 'Document Reader', value: 'doc_reader' },
  { label: 'Accounting', value: 'accounting' },
  { label: 'Finance', value: 'finance' },
];

const permissionItems = [
  { label: 'Supervisor', value: 'supervisor' },
  { label: 'Editor', value: 'editor' },
  { label: 'Reader', value: 'reader' },
];

const levelItems = [
  { label: 'Level 2', value: '2' },
  { label: 'Level 3', value: '3' },
  { label: 'Level 4', value: '4' },
];

export default function UserDialog({
  adminUser,
  setAdminUser,
  openDialog,
  setOpenDialog,
  completion,
}) {
  const { setLoading } = useContext(UserContext);
  const [name, setName] = useState(null);
  const [employeeCode, setEmployeeCode] = useState(null);
  const [email, setEmail] = useState(null);
  const [group, setGroup] = useState(null);
  const [permission, setPermission] = useState(null);
  const [level, setLevel] = useState(null);

  useEffect(() => {
    if (adminUser) {
      setName(adminUser?.chineseName);
      setEmployeeCode(adminUser?.employeeCode);
      setEmail(adminUser?.email);
      setGroup(adminUser?.group);
      setPermission(adminUser?.permission);
      setLevel(adminUser?.level);
    }
  }, [adminUser]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setAdminUser(null);
    setName(null);
    setEmployeeCode(null);
    setEmail(null);
    setGroup(null);
    setPermission(null);
    setLevel(null);
  };

  const checkInputData = () => {
    if (email === null) {
      return 'Please enter email.';
    } else if (group === null) {
      return 'Please select group.';
    } else if (group === 'accounting' || group === 'finance') {
      if (permission === null) {
        return 'Please select permission.';
      }
      if (permission === 'supervisor' && level === null) {
        return 'Please select level.';
      }
    }
    return null;
  };

  const handleInviteUser = async () => {
    const invalidDataMsg = checkInputData();
    if (invalidDataMsg) {
      completion({ error: true, msg: invalidDataMsg });
    } else {
      const data = { email: email, group: group };
      if (group === 'accounting' || group === 'finance') {
        data['permission'] = permission;

        if (permission === 'supervisor') {
          data['level'] = parseInt(level);
        }
      }

      handleCloseDialog();

      setLoading(true);

      const response = await adminsAPI.createAdmin(data);

      setLoading(false);

      if (response) {
        completion({
          msg: 'Sent invitation email to ' + email,
          error: false,
        });
      } else {
        completion({
          msg: 'Create admn fail',
          error: true,
        });
      }
    }
  };

  const handleUpdateUser = async () => {
    const invalidDataMsg = checkInputData();
    if (invalidDataMsg) {
      completion({ error: true, msg: invalidDataMsg });
    } else {
      const data = {
        chineseName: name,
        employeeCode: employeeCode,
        group: group,
      };
      if (group === 'accounting' || group === 'finance') {
        data['permission'] = permission;
        data['level'] = permission === 'supervisor' ? parseInt(level) : null;
      } else {
        data['permission'] = null;
        data['level'] = null;
      }

      handleCloseDialog();

      setLoading(true);

      const response = await adminsAPI.updateAdmin(adminUser?.id, data);

      setLoading(false);

      if (response) {
        completion({
          msg: 'Success',
          error: false,
        });
      } else {
        completion({
          msg: 'Fail to update admin',
          error: true,
        });
      }
    }
  };

  const handleCopyRegisterLink = async () => {
    handleCloseDialog();

    setLoading(true);

    const response = await adminsAPI.getRegisterLink(adminUser?.id);

    setLoading(false);

    if (response) {
      navigator.clipboard.writeText(response?.registerLink);

      completion({
        msg: 'Register link is copied to clipboard',
        error: false,
      });
    } else {
      completion({
        msg: 'User is already registered',
        error: true,
      });
    }
  };

  const handleCopyResetPasswordLink = async () => {
    handleCloseDialog();

    setLoading(true);

    const response = await adminsAPI.getResetPasswordLink(adminUser?.id);

    setLoading(false);

    if (response) {
      navigator.clipboard.writeText(response?.resetPasswordLink);

      completion({
        msg: 'Reset password link is copied to clipboard',
        error: false,
      });
    } else {
      completion({
        msg: 'Fail to get reset password link',
        error: true,
      });
    }
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth={'xs'}
        fullWidth={true}>
        <DialogTitle>{adminUser ? 'User Details' : 'Add User'}</DialogTitle>

        <DialogContent>
          {adminUser && (
            <>
              <TextField
                autoFocus
                margin="dense"
                label="Name"
                fullWidth
                autoComplete="off"
                variant="outlined"
                placeholder="Enter name"
                InputLabelProps={{ shrink: true }}
                sx={{ marginBottom: 4 }}
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />

              <TextField
                autoFocus
                margin="dense"
                label="Employee Code"
                fullWidth
                autoComplete="off"
                variant="outlined"
                placeholder="Enter employee code"
                InputLabelProps={{ shrink: true }}
                sx={{ marginBottom: 4 }}
                value={employeeCode}
                onChange={(event) => {
                  setEmployeeCode(event.target.value);
                }}
              />
            </>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Email"
            fullWidth
            autoComplete="off"
            variant="outlined"
            placeholder="Enter email"
            InputLabelProps={{ shrink: true }}
            disabled={adminUser ? true : false}
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />

          <Selector
            label="Group"
            gutterTop
            items={groupItems}
            value={group}
            setValue={setGroup}></Selector>

          {(group === 'accounting' || group === 'finance') && (
            <Selector
              label="Permission"
              gutterTop
              items={permissionItems}
              value={permission}
              setValue={setPermission}></Selector>
          )}

          {(group === 'accounting' || group === 'finance') &&
            permission === 'supervisor' && (
              <Selector
                label="Level"
                gutterTop
                items={levelItems}
                value={level}
                setValue={setLevel}></Selector>
            )}

          {adminUser && (
            <Box sx={{ marginTop: '15px', marginBottom: '-10px' }}>
              <Button
                color="secondary"
                size="small"
                startIcon={<ContentCopyIcon />}
                onClick={handleCopyRegisterLink}>
                register link
              </Button>

              <Button
                color="secondary"
                size="small"
                startIcon={<ContentCopyIcon />}
                sx={{ marginLeft: '30px' }}
                onClick={handleCopyResetPasswordLink}>
                reset password link
              </Button>
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          {adminUser ? (
            <Button onClick={handleUpdateUser}>Update</Button>
          ) : (
            <Button onClick={handleInviteUser}>Invite</Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
