import { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UserDetail from './components/UserDetail';
import UserImage from '../../components/UserImage';
import { UserContext } from '../../context/UserContext';
import UserInfo from './components/UserInfo';
import { Box, Container } from '@mui/material';

export default function CustomerInfo() {
  const [userImageIndex, setUserImageIndex] = useState(0);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setNavTitle, setSubNavTitle } = useContext(UserContext);

  useEffect(() => {
    // 此頁是要從 /customerService 才能轉導過來的，所以如果沒有 state 就導回去
    if (state) {
      setSubNavTitle(`${state.name?state.name:""}(${state.memberId})`);
    } else {
      navigate('/customerService');
      setNavTitle('會員資料查詢');
    }
  }, [navigate, setNavTitle, state, setSubNavTitle]);

  return (
    <>
      {state && (
        <Container maxWidth="md" sx={{ marginTop: '40px' }}>
          <UserInfo userData={state} />
          <Box sx={{ marginTop: '30px' }}>
            {state.orderNumber !== null && (
              <UserImage
                orderNumber={state.orderNumber}
                value={userImageIndex}
                setValue={setUserImageIndex}
                hiddenUploadBtn={false}
                DisableTabSwitch={false}
              />
            )}
          </Box>
          <Box sx={{ marginTop: '30px' }}>
            <UserDetail
              id={state.memberId}
            />
          </Box>
        </Container>
      )}
    </>
  );
}
