import { Box, CircularProgress } from '@mui/material';
import { useState, useContext } from 'react';
import StyledDataGrid from '../../../components/StyledDataGrid';
import { UserContext } from '../../../context/UserContext';
import { customerAPI } from '../../../apis/customerAPI';
import moment from 'moment/moment';
import { useEffectOnce } from '../../../utils/UseEffectOnce';

const columns = [
  {
    field: 'txId',
    headerName: '單號',
    width: 170,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'method',
    headerName: '類型',
    width: 142,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'date',
    headerName: '日期',
    width: 142,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'time',
    headerName: '時間',
    width: 142,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'amount',
    headerName: '金額',
    type: 'number',
    width: 142,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'status',
    headerName: '狀態',
    width: 142,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
  {
    field: 'note',
    headerName: '備註',
    width: 142,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
  },
];
const status = {
  succeed: '出金成功',
  open: '出金處理中',
  processing: '出金處理中',
  rejected: '已退匯',
  txt_done: '出金處理中',
  fbo_confirmed: '出金處理中',
  refunding: '退匯處理中'
};
const depositMethod = {
  withdraw: '提領出金',
  bank: '銀行轉帳',
  store: '門市儲值',
  order: '訂單儲值',
};

export default function CustomerWithdraw({ id }) {
  const [withdrawRecords, setWithdrawRecords] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffectOnce(() => {
    const fetchAccounts = async () => {
      setIsFetching(true);
      const response = await customerAPI.getUserWithdraw(id);
      if (response !== null) {
        setWithdrawRecords(response);
      }
      setIsFetching(false);
    };

    fetchAccounts();
  }, [setIsFetching, id]);

  const rows = withdrawRecords.map((data, index) => {
    return {
      id: index + 1,
      txId: data.txId,
      method: depositMethod[data.type],
      date: moment.unix(data.createdTime).format('YYYY/MM/DD'),
      time: moment.unix(data.createdTime).format('HH:mm'),
      amount: data.amount,
      status: status[data.status],
      note: '無',
    };
  });

  return (
    <Box sx={{ height: 'calc(100vh - 450px)', width: '100%' }}>
    {isFetching && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {!isFetching && <StyledDataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
      />}
    </Box>
  );
}
