import { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../context/UserContext';
import Authorizer from '../../utils/Authorizer';
import UserQueryPanel from '../../components/UserQueryPanel';
import { Container } from '@mui/material';
import { customerAPI } from '../../apis/customerAPI';
import { useNavigate } from 'react-router-dom';
import AlertMessage from '../../components/AlertMessage';

export default function RiskControl() {
  const { setSubNavTitle, setLoading } = useContext(UserContext);
  const [alert, setAlert] = useState('');
  const navigate = useNavigate();

  async function handleSubmit(payload) {
    setLoading(true);
    const response = await customerAPI.getAuditDataForOperationManager(payload);
    setLoading(false);

    if (response?.error) {
      setAlert(response.error.message);
    } else {
      setSubNavTitle(`${response.name?response.name:""}(${response.memberId})`);
      navigate(`${response.memberId}/kyc`, { state: response });
    }
  }

  useEffect(() => {
    setSubNavTitle('');
  }, [setSubNavTitle]);

  return (
    <>
      <Container maxWidth="md" sx={{ marginTop: '40px' }}>
        <UserQueryPanel handleSubmit={handleSubmit} />
      </Container>
      <AlertMessage alertState={{ alert, setAlert, severity: 'warning' }} />
    </>
  );
}
