import { useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Panel from '../../../components/Panel';
import { TitleLabel } from '../../../components/Label';
import { Box, Grid, TextField, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { UserContext } from '../../../context/UserContext';
import { customerAPI } from '../../../apis/customerAPI';
import AlertMessage from '../../../components/AlertMessage';

export default function UserQueryPanel() {
  const [alert, setAlert] = useState('');
  const { setLoading } = useContext(UserContext);
  const phoneRef = useRef(null);
  const idRef = useRef(null);
  const emailRef = useRef(null);
  const navigate = useNavigate();

  const customerInfoTitles = [
    {
      label: '門號',
      placeholder: '請輸入門號',
      ref: phoneRef,
    },
    {
      label: '證號',
      placeholder: '請輸入證號',
      ref: idRef,
    },
    {
      label: 'Email',
      placeholder: '請輸入Email',
      ref: emailRef,
    },
  ];

  const fetchUserLists = async (payload) => {
    setLoading(true);
    const response = await customerAPI.getUser(payload);
    setLoading(false);
    if (response?.error) {
      setAlert(response.error.message);
      clearInputs();
    } else {
      navigate('/customerService/customerInfo', { state: response });
    }
  };

  const handleClickSearch = async () => {
    if (
      !phoneRef.current.value &&
      !idRef.current.value &&
      !emailRef.current.value
    ) {
      setAlert('輸入框請勿留空');
      return;
    }

    let payload = {};
    if (phoneRef.current.value) payload['phoneNumber'] = phoneRef.current.value;
    if (idRef.current.value) payload['identityNumber'] = idRef.current.value;
    if (emailRef.current.value) payload['email'] = emailRef.current.value;

    fetchUserLists(payload);
  };

  const setInput = (event, item) => {
    item.ref.current.value = event.target.value;
  };

  const clearInputs = (index) => {
    for (let i = 0; i < customerInfoTitles.length; i++) {
      if (i !== index) {
        customerInfoTitles[i].ref.current.value = null;
      }
    }
  };

  return (
    <>
      <Panel>
        <Grid container spacing={2}>
          {customerInfoTitles.map((item, index) => (
            <Grid
              item
              xs={6}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              key={index}>
              <TitleLabel fontSize="15px">{item.label}</TitleLabel>
              <Box sx={{ width: '320px' }}>
                <TextField
                  fullWidth
                  width="20px"
                  placeholder={item.placeholder}
                  size="small"
                  onFocus={() => clearInputs(index)}
                  onChange={(e) => setInput(e, item)}
                  inputRef={item.ref}></TextField>
              </Box>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button
              variant="text"
              sx={{ float: 'right' }}
              onClick={handleClickSearch}>
              <SearchIcon fontSize="small" />
              查詢
            </Button>
            <Button
              variant="text"
              sx={{ float: 'right' }}
              onClick={() => clearInputs(-1)}>
              <RestartAltIcon fontSize="small" />
              重置
            </Button>
          </Grid>
        </Grid>
      </Panel>
      <AlertMessage alertState={{ alert, setAlert }} />
    </>
  );
}
