import React from 'react';
import { TextField, Tooltip } from '@mui/material';

export default function TextInput({
  label,
  placeholder,
  gutterTop,
  gutterBottom,
  multiline,
  disabled,
  InputProps,
  type,
  size,
  value,
  setValue,
  tip,
  maxLength,
}) {
  const sx = {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
  };
  if (gutterTop) sx['marginTop'] = 4;
  if (gutterBottom) sx['marginBottom'] = 4;

  const formatValue = (value) => {
    if (type === 'number') {
      return parseInt(value).toString();
    }
    return value;
  };

  const handleTextChange = (event) => {
    const text = formatValue(event.target.value);
    if (maxLength !== undefined && text.length >= maxLength + 1) {
      return;
    }
    setValue(text);
  };

  const singleLineTextField = () => {
    return (
      <Tooltip title={tip} arrow>
        <TextField
          sx={sx}
          label={label}
          fullWidth
          placeholder={placeholder}
          color="secondary"
          autoComplete="off"
          value={value ? value : ''}
          onChange={handleTextChange}
          InputLabelProps={{ shrink: true }}
          disabled={disabled}
          InputProps={InputProps}
          type={type ? type : undefined}
          size={size ? size : 'normal'}
        />
      </Tooltip>
    );
  };

  const multipleLinesTextField = () => {
    return (
      <Tooltip title={tip} arrow>
        <style jsx>{`
          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type='number'] {
            -moz-appearance: textfield;
          }
        `}</style>
        <TextField
          sx={sx}
          label={label}
          fullWidth
          placeholder={placeholder}
          color="secondary"
          autoComplete="off"
          value={value ? value : ''}
          onChange={handleTextChange}
          InputLabelProps={{ shrink: true }}
          rows={4}
          multiline
          InputProps={InputProps}
          type={type ? type : undefined}
          size={size ? size : 'normal'}
        />
      </Tooltip>
    );
  };

  return <>{multiline ? multipleLinesTextField() : singleLineTextField()}</>;
}
