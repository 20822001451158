import { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import { UserContext } from '../context/UserContext';
import Authorizer from '../utils/Authorizer';
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import ArticleIcon from '@mui/icons-material/Article';
import PieChartIcon from '@mui/icons-material/PieChart';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import SendIcon from '@mui/icons-material/Send';

export const sideMenuWidth = 240;
const menuItemHeight = 60;

const sxDrawer = {
  backgroundColor: '#F7F6F3',
  width: `${sideMenuWidth}px`,
};

const sxItem = {
  height: menuItemHeight,
  cursor: 'pointer',
};

const sxItemSelected = {
  height: menuItemHeight,
  backgroundColor: 'grey.200',
  cursor: 'pointer',
};

const sxIcon = {
  marginLeft: 2,
  marginRight: -2.5,
  color: 'secondary.main',
};

const sxIconSelected = {
  marginLeft: 2,
  marginRight: -2.5,
  color: 'primary.main',
};

const sxText = {
  fontWeight: 400,
  variant: 'body3',
  color: 'secondary.main',
};

const sxTextSelected = {
  fontWeight: 600,
  variant: 'body3',
  color: 'primary.main',
};

const LogoImage = styled('img')(({ theme }) => ({
  marginTop: '15px',
  marginBottom: '20px',
  width: '65%',
}));

const menuItems = [
  {
    text: '首頁',
    icon: <HomeIcon fontSize="small" />,
    path: '/',
    subPath: [],
    permissions: [{ action: 'GET', object: '/cms/admins/me' }],
  },
  {
    text: '會員資料查詢',
    icon: <PersonIcon fontSize="small" />,
    path: '/customerService',
    subPath: [new RegExp('/customerService/customerInfo')],
    permissions: [{ action: 'GET', object: '/cms/customer-services' }],
  },
  {
    text: '交易查詢',
    icon: <ArticleIcon fontSize="small" />,
    path: '/tradeList',
    subPath: [],
    permissions: [],
  },
  {
    text: '帳務',
    icon: <PieChartIcon fontSize="small" />,
    path: '/accounting',
    subPath: [],
    permissions: [{ action: 'GET', object: '/cms/accounting' }],
  },
  {
    text: '風控審查',
    icon: <VerifiedUserIcon fontSize="small" />,
    path: '/riskReview',
    subPath: [
      new RegExp('/riskReview/.+/kyc'),
      new RegExp('/riskReview/.+/aml'),
    ],
    permissions: [],
  },
  {
    text: '風控審查主管',
    icon: <VerifiedUserIcon fontSize="small" />,
    path: '/riskReviewConfirm',
    subPath: [new RegExp('/riskReviewConfirm/.+')],
    permissions: [],
  },
  {
    text: '通知發送',
    icon: <SendIcon fontSize="small" />,
    path: '/pushNotificationService',
    subPath: [],
    permissions: [],
  },
  {
    text: 'User Management',
    icon: <GroupIcon fontSize="small" />,
    path: '/userManagement',
    subPath: [],
    permissions: [],
  },
];

export default function SideMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setNavTitle, setSubNavTitle, availablePages } =
    useContext(UserContext);

  function selectMenuItem(item) {
    navigate(item.path);
    setNavTitle(item.text);
    setSubNavTitle('');
  }
  console.log(availablePages);

  return (
    <Drawer variant="permanent" anchor="left" PaperProps={{ sx: sxDrawer }}>
      <Box display="flex" justifyContent="center">
        <LogoImage src="../images/logo.png" />
      </Box>

      <List>
        {menuItems.map(
          (item) =>
            availablePages &&
            availablePages.includes(item.path) && (
              <div key={item.text}>
                <ListItem
                  onClick={() => selectMenuItem(item)}
                  sx={
                    location.pathname === item.path ||
                    item.subPath.some((regexObject) =>
                      regexObject.test(location.pathname),
                    )
                      ? sxItemSelected
                      : sxItem
                  }>
                  <ListItemIcon
                    sx={
                      location.pathname === item.path ||
                      item.subPath.some((regexObject) =>
                        regexObject.test(location.pathname),
                      )
                        ? sxIconSelected
                        : sxIcon
                    }>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={
                      location.pathname === item.path ||
                      item.subPath.some((regexObject) =>
                        regexObject.test(location.pathname),
                      )
                        ? sxTextSelected
                        : sxText
                    }
                    primary={item.text}
                  />
                </ListItem>
              </div>
            ),
        )}
      </List>
    </Drawer>
  );
}
