import { Box, Grid } from '@mui/material';
import Panel from '../../../components/Panel';
import { TitleLabel, SubTitleLabel } from '../../../components/Label';

export default function UserInfo({ userData, type }) {
  return (
    <Panel>
      <Grid container spacing={2}>
        {userData[type].map((item) => (
          <Grid item key={item.title} xs={6}>
            <Box
              sx={{
                display: 'flex',
                position: 'relative',
              }}>
              <TitleLabel fontSize="15px">{item.title}</TitleLabel>
              <SubTitleLabel
                sx={{
                  position: 'absolute',
                  left: '160px',
                  lineHeight:'1.6'
                }}>
                {item.value}
              </SubTitleLabel>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Panel>
  );
}
