import Popper from "@mui/material/Popper";
import { useState } from "react";
import CustomGrid from "./CustomGrid";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Button, IconButton, Fade, Paper } from "@mui/material";

const sxPaper = {
  padding: "20px 60px",
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.2)",
  display: "flex",
  justifyContent: "center",
  width: "400px",
};

const sxIconButton = {
  position: "absolute",
  right: "16px",
  top: "16px",
};

const sxButton = {
  padding: "4px",
  width: "124px",
  lineHeight: "1.2",
  boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.05)",
};

export default function SimplePopper({
  buttonText,
  popperContent,
  popperPosition,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    if (placement !== newPlacement || !open) {
      setPlacement(newPlacement);
      setOpen(true);
    } else {
      setOpen(!open);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box>
      <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={sxPaper}>
              <IconButton sx={sxIconButton} onClick={handleClose}>
                <CloseRoundedIcon sx={{ color: "primary", fontSize: 20 }} />
              </IconButton>
              <Box>
                <CustomGrid data={popperContent} underline></CustomGrid>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>

      <Button
        color="primary"
        variant="contained"
        sx={sxButton}
        onClick={handleClick(popperPosition)}
      >
        {buttonText}
      </Button>
    </Box>
  );
}
