import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Stack,
  Divider,
  ImageListItem,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Panel from './Panel';
import { TitleLabel, SubTitleLabel } from './Label';
import ImageModal from './ImageModal';
import useIdentityVerificationImageBase64 from '../hooks/useIdentityVerificationImageBase64';



function IDFDImage({ id, url, description }) {
  return (
    <ImageModal key={id} url={url} description={description}>
      <ImageListItem
        cols={1}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Box
          sx={{
            width: '80%',
            height: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
          }}>
          <img
            src={url}
            alt={description}
            loading="lazy"
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '8px',
              objectFit: 'contain',
            }}
          />
        </Box>
      </ImageListItem>
    </ImageModal>
  );
}

function IDFDImageBase64({id, fileContentBs64, description}) {
  const payload = {file_path: fileContentBs64}
  const {isPending, imageBase64} = useIdentityVerificationImageBase64(payload)
  // const base64 = `data:image/jpg;base64,${imageBase64}`;

  return (
    <ImageModal key={id} url={imageBase64} description={description}>
      <ImageListItem
        cols={1}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Box
          sx={{
            width: '80%',
            height: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
          }}>
          <img
            src={imageBase64}
            alt={description}
            loading="lazy"
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '8px',
              objectFit: 'contain',
            }}
          />
        </Box>
      </ImageListItem>
    </ImageModal>
  );
}


function IDFDImageDetails({ data }) {
  return (
    <Panel>
      <Grid
        container
        direction="column"
        align="center"
        alignItems="center"
        justifyContent="center">
        {/* Table Header */}
        <Grid container item fontWeight="bold" marginBottom="10px">
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            圖檔
          </Grid>
          <Grid item xs={3}>
            分數
          </Grid>
          <Grid item xs={3}>
            Y/N
          </Grid>
        </Grid>
        {/* Table Data */}
        <Grid container item direction="column">
          {data &&
            data.map((ele, index) => (
              <Grid container item>
                <Grid item xs={1} alignItems="center" padding="50%, 0">
                  {index + 1}
                </Grid>
                <Grid item xs={5}>
                  <IDFDImageBase64
                    id={ele?.idfdImgSeq}
                    fileContentBs64={ele?.fileContentBs64}
                    description={ele?.description}
                  />
                </Grid>
                <Grid container item direction="column" xs={3}>
                  <Grid container item alignItems="flex">
                    <Grid item xs={6}>
                      台灣
                    </Grid>
                    <Grid item xs={6}>
                      {ele?.feature1Score}
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item xs={6}>
                      蝴蝶
                    </Grid>
                    <Grid item xs={6}>
                      {ele?.feature2Score}
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item xs={6}>
                      TAIWAN
                    </Grid>
                    <Grid item xs={6}>
                      {ele?.feature3Score}
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item xs={6}>
                      金屬防偽線
                    </Grid>
                    <Grid item xs={6}>
                      {ele?.feature4Score}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item direction="column" xs={3}>
                  <Grid item>{ele?.feature1Result}</Grid>
                  <Grid item>{ele?.feature2Result}</Grid>
                  <Grid item>{ele?.feature3Result}</Grid>
                  <Grid item>{ele?.feature4Result}</Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Panel>
  );
}

function IDFDOverview({ data }) {
  return (
    <Panel>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        sx={{
          display: 'flex',
          position: 'relative',
        }}>
        <Grid container item>
          <Grid item xs={6}>
            <TitleLabel fontSize="13px" align="center">
              審核結果
            </TitleLabel>
          </Grid>
          <Grid item xs={6}>
            <TitleLabel fontSize="13px" align="center">
              通過/不通過
            </TitleLabel>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid container item>
            <Grid item xs={3}>
              <TitleLabel />
            </Grid>
            <Grid item xs={3}>
              <TitleLabel fontSize="13px" align="center">
                分數
              </TitleLabel>
            </Grid>
            <Grid item xs={3}>
              <TitleLabel fontSize="13px" align="center">
                Y/N
              </TitleLabel>
            </Grid>
            <Grid item xs={3}>
              <TitleLabel fontSize="13px" align="center">
                Pic NO
              </TitleLabel>
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={3}>
              <SubTitleLabel align="center">台灣</SubTitleLabel>
            </Grid>
            <Grid item xs={3}>
              <SubTitleLabel align="center">
                {data?.feature1Score}
              </SubTitleLabel>
            </Grid>
            <Grid item xs={3}>
              <SubTitleLabel align="center">
                {data?.feature1Result}
              </SubTitleLabel>
            </Grid>
            <Grid item xs={3}>
              <SubTitleLabel align="center">
                {data?.feature1ImgSeq}
              </SubTitleLabel>
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={3}>
              <SubTitleLabel align="center">蝴蝶</SubTitleLabel>
            </Grid>
            <Grid item xs={3}>
              <SubTitleLabel align="center">
                {data?.feature2Score}
              </SubTitleLabel>
            </Grid>
            <Grid item xs={3}>
              <SubTitleLabel align="center">
                {data?.feature2Result}
              </SubTitleLabel>
            </Grid>
            <Grid item xs={3}>
              <SubTitleLabel align="center">
                {data?.feature2ImgSeq}
              </SubTitleLabel>
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={3}>
              <SubTitleLabel align="center">TAIWAN</SubTitleLabel>
            </Grid>
            <Grid item xs={3}>
              <SubTitleLabel align="center">
                {data?.feature3Score}
              </SubTitleLabel>
            </Grid>
            <Grid item xs={3}>
              <SubTitleLabel align="center">
                {data?.feature3Result}
              </SubTitleLabel>
            </Grid>
            <Grid item xs={3}>
              <SubTitleLabel align="center">
                {data?.feature3ImgSeq}
              </SubTitleLabel>
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={3}>
              <SubTitleLabel align="center">金屬防偽線</SubTitleLabel>
            </Grid>
            <Grid item xs={3}>
              <SubTitleLabel align="center">
                {data?.feature4Score}
              </SubTitleLabel>
            </Grid>
            <Grid item xs={3}>
              <SubTitleLabel align="center">
                {data?.feature4Result}
              </SubTitleLabel>
            </Grid>
            <Grid item xs={3}>
              <SubTitleLabel align="center">
                {data?.feature4ImgSeq}
              </SubTitleLabel>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Panel>
  );
}

function ImagesButton({ handleOnClick, sxProps }) {
  return (
    <Box sx={{ textAlign: 'right' }}>
      <Button
        variant="contained"
        color="primary"
        sx={sxProps}
        onClick={handleOnClick}>
        詳細圖檔
      </Button>
    </Box>
  );
}

export default function IDFDReview({ summary, images, reviewType }) {
  const [showImages, setShowImages] = useState(false);
  const location = useLocation();

  const show = location.pathname !== '/customerService/customerInfo';

  const handleShowImages = () => {
    setShowImages((prev) => !prev);
  };

  if (show) {
    if (reviewType === 'riskReview') {
      return (
        <Panel>
          <Stack direction="column" spacing={4}>
            <TitleLabel fontSize="18px">IDFD人工審查</TitleLabel>
            <Divider />
            <TitleLabel fontSize="15px">IDFD 圖檔標籤總評</TitleLabel>
            <IDFDOverview data={summary} />
            <ImagesButton handleOnClick={handleShowImages} sxProps={{ width: '110px' }}/>
            {showImages && (
              <TitleLabel fontSize="15px">IDFD 詳細圖檔</TitleLabel>
            )}
            {showImages && <IDFDImageDetails data={images} />}
          </Stack>
        </Panel>
      );
    } else {
      return (
        <Stack direction="column" spacing={4}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <TitleLabel fontSize="18px">IDFD人工審查</TitleLabel>
            </AccordionSummary>
            <AccordionDetails>
              <Divider sx={{ mb: 2 }} />
              <TitleLabel fontSize="15px" sx={{ mb: 2 }}>
                IDFD 圖檔標籤總評
              </TitleLabel>
              <IDFDOverview data={summary} />
              <ImagesButton handleOnClick={handleShowImages} sxProps={{ width: '110px', mt: 2 }}/>
              {showImages && (
                <TitleLabel fontSize="15px" sx={{ mb: 2 }}>
                  IDFD 詳細圖檔
                </TitleLabel>
              )}
              {showImages && <IDFDImageDetails data={images} />}
            </AccordionDetails>
          </Accordion>
        </Stack>
      );
    }
  }
  return <></>;
}
