import { Container } from '@mui/material';
import Panel from '../../components/Panel';
import { Label } from '../../components/Label';
import Authorizer from '../../utils/Authorizer';

export default function TradeList() {
  return (
    <Container maxWidth="md" sx={{ marginTop: '40px' }}>
      <Panel>
        <Label>我的交易清單🔥</Label>
      </Panel>
    </Container>
  );
}
