import Divider from '@mui/material/Divider';
import { TitleLabel } from './Label';
import DenseTable from './DenseTable';

export default function ReviewRecord({ orderNumber }) {
  return (
    <>
      <TitleLabel fontSize="16px">審查紀錄</TitleLabel>
      <Divider sx={{ marginY: '10px' }} />
      <DenseTable orderNumber={orderNumber} />
    </>
  );
}
