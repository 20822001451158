import { Box, ImageListItem, ImageListItemBar } from '@mui/material';
import ImageModal from '../../../../components/ImageModal';
import useIdentityVerificationImageBase64 from '../../../../hooks/useIdentityVerificationImageBase64';

export default function ImageBase64ItemWithModel({
  id,
  fileContentBs64,
  description,
}) {
  const payload = { file_path: fileContentBs64 };
  const { isPending, imageBase64 } =
    useIdentityVerificationImageBase64(payload);
  // const base64 = `data:image/jpg;base64,${imageBase64}`;
  return (
    <ImageModal key={id} url={imageBase64} description={description}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          backgroundColor: '#F0F0F0',
          padding: '10px',
          margin: '5px',
          border: 'solid #D0D0D0 1px',
          borderRadius: '8px',
        }}>
        <ImageListItem
          cols={1}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <Box
            sx={{
              width: '100px',
              height: '80px',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
            }}>
            <img
              src={imageBase64}
              alt={description}
              loading="lazy"
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '8px',
                objectFit: 'contain',
              }}
            />
          </Box>
          <ImageListItemBar
            title={id}
            subtitle={<span>by: {id}</span>}
            position="below"
            actionPosition="right"
            sx={{
              display: 'flex',
              marginLeft: '10px',
              maxWidth: '280px',
            }}
          />
        </ImageListItem>
      </Box>
    </ImageModal>
  );
}
