import { api } from './configs/axiosFileUploadConfigs';

export const uploadImageAPI = {
  uploadImage: async function (payload) {
    try {
      const response = await api.request({
        url: '/identity-check/file/upload',
        method: 'POST',
        data: payload,
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
};
