import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
} from '@mui/material';
import { SubTitleLabel } from '../../../components/Label';
import CloseIcon from '@mui/icons-material/Close';
import Selector from '../../../components/Selector';
import { useState } from 'react';
import CustomGrid from '../../../components/CustomGrid';

function AssetsDialog({ data }) {
  const [monthSelect, setMonthSelect] = useState(
    `${data[0]?.year}/${data[0]?.month}`,
  );
  const monthRecord = data.find(
    (record) =>
      record.year === monthSelect?.split('/')[0] &&
      record.month === monthSelect?.split('/')[1],
  );
  const cryptoRecord = monthRecord?.currencies;
  const assetRecord = [
    { title: '台幣入金金額', value: monthRecord?.deposit },
    { title: '購買虛幣花費', value: monthRecord?.spend },
    { title: '虛幣賣出所得', value: monthRecord?.sell },
    { title: '提幣金額', value: monthRecord?.withdraw },
    { title: '台幣淨額變動', value: monthRecord?.twdValueChange },
  ];

  const monthSelectorItems = data.map((record) => {
    return {
      label: `${record.year}/${record.month}`,
      value: `${record.year}/${record.month}`,
    };
  });
  return (
    <>
      <Selector
        label="select month"
        gutterBottom
        gutterTop
        items={monthSelectorItems}
        value={monthSelect}
        setValue={setMonthSelect}
      />
      <Grid container spacing={2} sx={{ marginBottom: '8px' }}>
        {assetRecord.map((record) => (
          <Grid
            key={record.title}
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            padding="5px 0">
            <SubTitleLabel>{record.title}</SubTitleLabel>
            <SubTitleLabel>{record.value}</SubTitleLabel>
          </Grid>
        ))}
      </Grid>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>幣種</TableCell>
            <TableCell align="center">買入</TableCell>
            <TableCell align="center">賣出</TableCell>
            <TableCell align="center">淨額變動</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cryptoRecord?.map((record) => (
            <TableRow
              key={record.id}
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0,
                },
              }}>
              <TableCell component="th" scope="row">
                {record.id}
              </TableCell>
              <TableCell align="center">{record.buy}</TableCell>
              <TableCell align="center">{record.sell}</TableCell>
              <TableCell align="center">{record.twdValueChange}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

export default function CustomerDialog({
  openDialog,
  setOpenDialog,
  tradeInfo,
  monthlyAssets,
}) {
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      maxWidth={'xs'}
      fullWidth={true}
      sx={{ position: 'absolute' }}>
      <DialogTitle>{tradeInfo ? '訂單明細' : '資產紀錄'}</DialogTitle>
      <DialogContent>
        {tradeInfo && <CustomGrid data={tradeInfo} padding="10px" />}
        {monthlyAssets && <AssetsDialog data={monthlyAssets} />}
      </DialogContent>
      <DialogActions
        sx={{
          position: 'absolute',
          top: '5px',
          right: '5px',
        }}>
        <CloseIcon onClick={handleCloseDialog} fontSize="large"></CloseIcon>
      </DialogActions>
    </Dialog>
  );
}
