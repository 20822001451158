import { useState, useContext } from 'react';
import TextField from '@mui/material/TextField';
import { RiskReviewContext } from '../pages/RiskReviewItem/context/RiskReviewContext';
import { RiskManagerReviewContext } from '../pages/RiskReviewConfirmItem/context/RiskManagerReviewContext';

export default function ReviewComment({ label, type, onChange }) {
  const riskReviewResult = useContext(RiskReviewContext);
  const riskManagerReviewResult = useContext(RiskManagerReviewContext);

  const CHARACTER_LIMIT = 500;

  function getComment() {
    if (type === 'kyc') {
      return riskReviewResult.kycReviewComment;
    } else if (type === 'aml') {
      return riskReviewResult.amlReviewComment;
    } else if (type === 'manager') {
      return riskManagerReviewResult.reviewComment;
    }
  }

  const [values, setValues] = useState(getComment());

  function handleChange(event) {
    setValues(event.target.value);
    if (type === 'kyc') {
      riskReviewResult.kycReviewComment = event.target.value;
    } else if (type === 'aml') {
      riskReviewResult.amlReviewComment = event.target.value;
    } else if (type === 'manager') {
      riskManagerReviewResult.reviewComment = event.target.value;
      if (onChange) {
        onChange(); 
      }
    }
  }

  return (
    <TextField
      fullWidth
      id="filled-multiline-static"
      label={label}
      multiline
      rows={8}
      variant="filled"
      inputProps={{
        maxLength: CHARACTER_LIMIT,
      }}
      value={values}
      helperText={`${values.length}/${CHARACTER_LIMIT}`}
      onChange={(event) => handleChange(event)}
      margin="normal"
    />
  );
}
