import { Box, Grid } from '@mui/material';
import Panel from '../../../components/Panel';
import { TitleLabel, SubTitleLabel } from '../../../components/Label';
import SimplePopper from '../../../components/SimplePopper';

export default function UserInfo({ userData }) {
  return (
    <>
      <Panel>
        <Grid container spacing={2}>
          {userData.info.map((item) => (
            <Grid item key={item.title} xs={6}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <TitleLabel fontSize="15px" minWidth="90px">
                  {item.title}
                </TitleLabel>
                <Box
                  sx={{
                    ml: '20px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width:'100%'
                  }}>
                  <SubTitleLabel>{item.value}</SubTitleLabel>

                  {item.title === '門號' && userData.phoneStatus && (
                    <SimplePopper
                      buttonText="手機號碼驗證狀態"
                      popperContent={userData.phoneStatus}
                      popperPosition="bottom-start"
                    />
                  )}
                  {item.title === 'Email' && userData.emailStatus && (
                    <SimplePopper
                      buttonText="Email驗證狀態"
                      popperContent={userData.emailStatus}
                      popperPosition="bottom-start"
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Panel>
    </>
  );
}
