import React from 'react';
import { Container } from '@mui/material';
import Panel from '../../components/Panel';
import { Label } from '../../components/Label';
import Authorizer from '../../utils/Authorizer';

export default function Accounting() {
  return (
    // <Authorizer permissions={[{ action: 'GET', object: '/cms/accounting' }]}>
    <Container maxWidth="md" sx={{ marginTop: '40px' }}>
      <Panel>
        <Label>哀哀，不會要把帳務系統移過來吧！</Label>
      </Panel>
    </Container>
    // </Authorizer>
  );
}
