import { Box, Grid } from '@mui/material';
import Panel from '../../../components/Panel';
import { TitleLabel, SubTitleLabel } from '../../../components/Label';

// const customerInfoTitles = [
//   { title: '會員姓名', value: '嘿嘿嘿' },
//   { title: '會員UID', value: '87' },
//   { title: '出生年月日', value: '1994/08/05' },
//   { title: '身分證字號', value: 'A123456789' },
//   { title: '身分證換補領', value: '換發' },
//   { title: '職業', value: '金融保險' },
//   { title: '方便聯絡時間', value: '平日上午' },
// ];

export default function UserInfo({ userInfo }) {
  return (
    <Panel>
      <Grid container spacing={2}>
        {userInfo.map((item) => (
          <Grid item key={item.title} xs={6}>
            <Box
              sx={{
                display: 'flex',
                position: 'relative',
                alignItems: 'center',
              }}>
              <TitleLabel fontSize="15px">{item.title}</TitleLabel>
              <SubTitleLabel
                sx={{
                  position: 'absolute',
                  left: '160px',
                }}>
                {item.value}
              </SubTitleLabel>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Panel>
  );
}
