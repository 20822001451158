import { useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { RiskReviewContext } from '../pages/RiskReviewItem/context/RiskReviewContext';
import { RiskManagerReviewContext } from '../pages/RiskReviewConfirmItem/context/RiskManagerReviewContext';

export default function AlertDialog({
  showDialog,
  setShowDialog,
  title,
  content,
  truthyBtnText,
  falsyBtnText,
  handleTruthyAction,
  type,
}) {
  const riskReviewResult = useContext(RiskReviewContext);
  const riskManagerReviewContext = useContext(RiskManagerReviewContext);

  const handleClose = () => {
    setShowDialog(false);
  };

  function handleConfirm() {
    setShowDialog(false);
    if (type === 'staff') {
      handleTruthyAction(riskReviewResult);
    } else if (type === 'manager') {
      handleTruthyAction(riskManagerReviewContext);
    }
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={showDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title"
          sx={{ display: 'flex', justifyContent: 'center' }}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginX: '30px',
          }}>
          <Button onClick={handleConfirm} variant="contained" autoFocus>
            {truthyBtnText}
          </Button>
          <Button onClick={handleClose} variant="outlined">
            {falsyBtnText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
