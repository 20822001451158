import React, { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import { Box } from '@mui/material';

import SideMenu from './SideMenu';
import NavigationBar from './NavigationBar';
import { sideMenuWidth } from './SideMenu';
import { navigationBarHeight } from './NavigationBar';
import { UserContext } from '../context/UserContext';

export const disableLayoutPaths = [
  // '/',
  '/login',
  '/otp',
  '/signUp',
  '/resetPassword',
  '/register',
  // '/customerService',
  // '/customerService/customerInfo',
  // '/tradeList',
  // '/accounting',
  // '/riskReview',
  // '/riskReview/:id/kyc',
  // /riskReview/:id/aml,
  // '/riskReviewConfirm'
  // '/riskReviewConfirm/:id/kyc'
  // '/riskReviewConfirm/:id/aml'
  // '/pushNotificationService',
  // '/userManagement',
];

const DivRoot = styled('div')(({ theme }) => ({
  display: 'flex',
}));

const ContainerBox = styled(Box)(({ theme }) => ({
  width: '100%',
  // backgroundColor: "#000000ff",
  paddingTop: `${navigationBarHeight}px`,
  paddingLeft: `${sideMenuWidth}px`,
}));

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!user && !disableLayoutPaths.includes(location.pathname)) {
      navigate('/login');
    }
  }, [location, user, navigate]);

  const layoutEnabled = () => {
    return (
      <DivRoot>
        <SideMenu />
        <NavigationBar />
        <ContainerBox> {children}</ContainerBox>
      </DivRoot>
    );
  };

  const layoutDisabled = () => {
    return <> {children} </>;
  };

  return (
    <>
      {disableLayoutPaths.includes(location.pathname)
        ? layoutDisabled()
        : layoutEnabled()}
    </>
  );
};

export default Layout;
