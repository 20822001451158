import { useEffect, useState } from "react";
import { customerAPI } from "../apis/customerAPI";
import ImageLoading from "../image_loading.png"

export default function useIdentityVerificationImageBase64(payload) {
  const [isPending, setIsPending] = useState(false);
  const [imageBase64, setImageBase64] = useState(ImageLoading);

  useEffect(() => {

    async function getImageBase64() {
      setIsPending(true);
      const result = await customerAPI.getImageBase64(payload);
      if (result) {
        setImageBase64(`data:image/jpg;base64,${result}`)
      }
      setIsPending(false);
    } 
    getImageBase64();
  }, [])

  return {isPending, imageBase64}
}