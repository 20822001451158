import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../context/UserContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { customerAPI } from '../apis/customerAPI';
import AlertMessage from '../components/AlertMessage';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function DenseTable({ orderNumber }) {
  const { setLoading } = useContext(UserContext);
  const [alert, setAlert] = useState('');
  const [reviewRecord, setReviewRecord] = useState();

  useEffect(() => {
    async function getReviewRecord(payload) {
      setLoading(true);
      const response = await customerAPI.getReviewRecord({
        orderNumber: payload,
      });
      setLoading(false);

      if (response?.error) {
        setAlert(response.error.message);
      } else {
        setReviewRecord(response);
      }
    }

    getReviewRecord(orderNumber);
  }, [setLoading, setAlert, orderNumber]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {reviewRecord &&
                reviewRecord.keys.map((key) => {
                  return <StyledTableCell key={key}>{key}</StyledTableCell>;
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {reviewRecord &&
              reviewRecord.values.map((row) => (
                <TableRow
                  key={row.time}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {row.time}
                  </TableCell>
                  <TableCell align="center">{row.kyc}</TableCell>
                  <TableCell align="center">{row.aml}</TableCell>
                  <TableCell align="left">{row.comment}</TableCell>
                  <TableCell align="center">{row.character}</TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AlertMessage alertState={{ alert, setAlert, severity: 'warning' }} />
    </>
  );
}
