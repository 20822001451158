import { api } from './configs/axiosConfigs';

export const customerAPI = {
  getUser: async function (payload) {
    try {
      const response = await api.request({
        url: '/customer-services/user',
        method: 'POST',
        data: payload,
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  getUserStatus: async function (id) {
    try {
      const response = await api.request({
        url: `/customer-services/user/${id}/status`,
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },
  getUserEddData: async function (id) {
    try {
      const response = await api.request({
        url: `/customer-services/user/${id}/edd-form`,
        method: 'GET',
      })
      return response.data
    } catch (error) {
      return null
    }
  },
  getUserBankAccount: async function (id) {
    try {
      const response = await api.request({
        url: `/customer-services/user/${id}/bank-account`,
        method: 'GET'
      })
      return response.data
    } catch (error) {
      return null
    }
  },
  getOrder: async function (id) {
    try {
      const response = await api.request({
        url: `/customer-services/user/${id}/orders`,
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },
  getUserAccounts: async function (id) {
    try {
      const response = await api.request({
        url: `/customer-services/user/${id}/accounts`,
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },
  getUserDeposit: async function (id) {
    try {
      const response = await api.request({
        url: `/customer-services/user/${id}/deposit-record`,
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },
  getUserWithdraw: async function (id) {
    try {
      const response = await api.request({
        url: `/customer-services/user/${id}/withdraw-record`,
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },
  login: async function (payload) {
    try {
      const response = await api.request({
        url: '/customer-services/login',
        method: 'POST',
        data: payload,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },
  getImageData: async function (payload) {
    try {
      const response = await api.request({
        url: '/identity-check/file/data', 
        method: 'POST',
        data: payload,
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  getImageBase64: async function(payload) {
    try {
      const response = await api.request({
        url: '/identity-check/file/image_to_base64',
        method: 'POST',
        data: payload,
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  getAuditDataForOperationStaff: async function (payload) {
    try {
      const response = await api.request({
        url: '/identity-check/operations-staff/audit-data',
        method: 'POST',
        data: payload,
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  getAuditDataForOperationManager: async function (payload) {
    try {
      const response = await api.request({
        url: '/identity-check/operations-manager/audit-data',
        method: 'POST',
        data: payload,
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  getReviewRecord: async function (payload) {
    try {
      const response = await api.request({
        url: '/identity-check/operations/review-record',
        method: 'POST',
        data: payload,
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  sendRiskReview: async function (payload) {
    try {
      const response = await api.request({
        url: '/identity-check/operations-staff/review',
        method: 'POST',
        data: payload,
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  sendManagerRiskReview: async function (payload) {
    try {
      const response = await api.request({
        url: '/identity-check/operations-manager/review',
        method: 'POST',
        data: payload,
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
};
