export const IDFD_SUMMARY = {
  feature1ImgSeq: 3,
  feature1Score: 80,
  feature1Result: 'Y',
  feature2ImgSeq: 11,
  feature2Score: 75,
  feature2Result: 'N',
  feature3ImgSeq: 11,
  feature3Score: 75,
  feature3Result: 'N',
  feature4ImgSeq: 11,
  feature4Score: 75,
  feature4Result: 'N',
}

export const IDFD_IMAGES = [...new Array(4)].map((_, index) => ({
  id: index,
  description: `Description ${index}`,
  name: `Name ${index}`,
  createTime: '',
  identityVerificationId: '8',
  url: `https://picsum.photos/id/${index + 200}/200`,
  idfdImgSeq: 11,
  feature1Score: 75,
  feature1Result: 'Y',
  feature2Score: 75,
  feature2Result: 'Y',
  feature3Score: 75,
  feature3Result: 'Y',
  feature4Score: 75,
  feature4Result: 'Y',
}));