import ImageList from '@mui/material/ImageList';
import ImageItemWithModel from './ImageItemWithModel';
import ImageBase64ItemWithModel from './ImageBase64ItemWithModel';

export default function ImageDisplayer({ images }) {
  return (
    <ImageList
      sx={{ width: '100%', minHeight: '40px' }}
      cols={2}
      rowHeight={70}
      gap={3}>
      {images.map((item, index) => (
        <ImageBase64ItemWithModel
          key={item.name}
          title={item.name}
          subtitle={
            <span>
              {item.createdTime} 存入單號 {item.identityVerificationId}
            </span>
          }
          id={item.name}
          fileContentBs64={item.fileContentBs64}
          description={item.description}
        />
      ))}
    </ImageList>
  );
}
