import { useState, useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Stack, Container, Typography, Button } from '@mui/material';
import { UserContext } from '../../context/UserContext';
import Authorizer from '../../utils/Authorizer';
import UserInfo from './components/UserInfo';
import Review from './components/Review';
import Divider from '@mui/material/Divider';
import ReviewComment from '../../components/ReviewComment';
import AlertDialog from '../../components/AlertDialog';
import { RiskManagerReviewContext } from './context/RiskManagerReviewContext';
import { customerAPI } from '../../apis/customerAPI';
import AlertMessage from '../../components/AlertMessage';
import ReviewRecord from '../../components/ReviewRecord';

export default function RiskReviewItem() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setNavTitle, setSubNavTitle, setLoading } = useContext(UserContext);
  const [showDialog, setShowDialog] = useState(false);
  const [alert, setAlert] = useState('');
  const [managerCanProceed, setManagerCanProceed] = useState(false);

  const value = useMemo(
    () => ({
      identityVerificationId: state.orderNumber,
      reviewComment: '',
      reviewResult: '',
    }),
    [state.orderNumber],
  );

  function handlePass() {
    setShowDialog(true);
    value.reviewResult = 'manager_approved';
  }

  function handleReturn() {
    setShowDialog(true);
    value.reviewResult = 'manager_rejected';
  }

  function handleChange() {
    setManagerCanProceed(value.reviewComment.length > 0);
  }

  async function sendManagerRiskReview(payload) {
    setLoading(true);
    const response = await customerAPI.sendManagerRiskReview(payload);
    setLoading(false);

    if (response?.error) {
      setAlert(response.error.message);
    } else {
      navigate('/riskReviewConfirm');
    }
  }

  useEffect(() => {
    // 此頁是要從 /customerService 才能轉導過來的，所以如果沒有 state 就導回去
    if (state) {
      setSubNavTitle(`${state.name?state.name:""}(${state.memberId})`);
    } else {
      navigate('/riskControl');
      setNavTitle('風控審查');
    }
  }, [navigate, setNavTitle, state, setSubNavTitle]);
  return (
    <RiskManagerReviewContext.Provider value={value}>
      <Container
        maxWidth={'md'}
        sx={{ marginTop: '40px', marginBottom: '40px' }}>
        <Typography variant="h6" gutterBottom>
          用戶基本資料
        </Typography>
        {state && (
          <Stack direction="column" spacing={4}>
            <UserInfo userInfo={state.info} />
            <Review
              orderNumber={state.orderNumber}
              disableKyc={state.disableKyc}
              disableAml={state.disableAml}
              ekycData={state.ekyc}
              ekycManualReviewData={state.ekycManualReview}
              amlData={state.aml}
              amlManualReviewData={state.amlManualReview}
            />
          </Stack>
        )}
        {state && (!state.disableKyc || !state.disableAml) && (
          <>
            <Divider color="#FF8E15" sx={{ marginY: '20px' }} />
            <Typography variant="h6" gutterBottom>
              主管審核
            </Typography>
            <ReviewComment
              label={'審查意見'}
              type="manager"
              onChange={handleChange}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="outlined"
                sx={{ borderRadius: '24px', width: '150px' }}
                disabled={!managerCanProceed}
                onClick={handleReturn}>
                退件
              </Button>
              <Button
                variant="contained"
                sx={{ borderRadius: '24px', width: '150px' }}
                disabled={!managerCanProceed}
                onClick={handlePass}>
                通過
              </Button>
            </Box>
            <AlertDialog
              showDialog={showDialog}
              setShowDialog={setShowDialog}
              title={'是否確定送出'}
              truthyBtnText={'確定送出意見'}
              falsyBtnText={'取消'}
              handleTruthyAction={sendManagerRiskReview}
              type="manager"
            />
          </>
        )}
        <Box sx={{ marginTop: '40px' }}>
          <ReviewRecord orderNumber={state.orderNumber} />
        </Box>
      </Container>
      <AlertMessage alertState={{ alert, setAlert, severity: 'warning' }} />
    </RiskManagerReviewContext.Provider>
  );
}
