import { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';

import { customerAPI } from '../../../apis/customerAPI';
import Panel from '../../../components/Panel';
import CustomGrid from '../../../components/CustomGrid';

export default function CustomerStatus({ id }) {
  const [isFetching, setIsFetching] = useState(false);
  const [fetchedData, setFetchedData] = useState([
    {
      title: '會員等級',
      value: '',
    },
    {
      title: '帳號建立時間',
      value: '',
    },
    {
      title: 'KYC 驗證狀態',
      value: '',
    },
    {
      title: 'AML 驗證狀態',
      value: '',
    },
  ]);

  useEffect(() => {
    async function getUserStatus() {
      setIsFetching(true);
      const response = await customerAPI.getUserStatus(id);
      if (response) {
        setFetchedData([
          {
            title: '會員等級',
            value: response.level ? response.level : '',
          },
          {
            title: '帳號建立時間',
            value: response.createdTime ? response.createdTime : '',
          },
          {
            title: 'KYC 驗證狀態',
            value: response.kycStatus ? response.kycStatus : '',
          },
          {
            title: 'AML 驗證狀態',
            value: response.amlStatus ? response.amlStatus : '',
          },
        ]);
      }
      setIsFetching(false);
    }

    getUserStatus();
  }, [id]);

  return (
    <Box sx={{ marginBottom: 4 }}>
      <Panel>
        {isFetching && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
        {!isFetching && <CustomGrid data={fetchedData} underline />}
      </Panel>
    </Box>
  );
}
