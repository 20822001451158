import React from 'react';
import { Container } from '@mui/material';
import Panel from '../../components/Panel';
import { Label } from '../../components/Label';

export default function Home() {
  return (
    <>
      <Container maxWidth="md" sx={{ marginTop: '40px' }}>
        <Panel>
          <Label>
            需要有一個任何人都有權限可以看的頁面作為初始頁，所以先保留個首頁
          </Label>
        </Panel>
      </Container>
    </>
  );
}
