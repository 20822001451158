import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

export default function InfoGrid({ data, direction, gridWidth }) {
  return (
    <Grid container spacing={2} direction={direction} marginTop={'8px'}>
      {data.map((item) => (
        <Grid item key={item.title} xs={gridWidth}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <Typography fontSize="15px" minWidth={'120px'} fontWeight={400}>
              {item.title}
            </Typography>
            <Typography
              fontSize="13px"
              color={'#6C757D'}
              sx={{
                marginLeft: '40px',
              }}>
              {item.value}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
