import React, { useContext } from 'react';
import Watermark from '@uiw/react-watermark';

import { UserContext } from '../context/UserContext';

export default function WatermarkComponent({ children, style }) {

  const { user } = useContext(UserContext);
  const watermark = `${user.employeeCode?user.employeeCode:''}${user.chineseName?user.chineseName:''}`

  return (
    <Watermark
      rotate={25}
      gapY={20}
      gapX={4}
      width={100}
      height={25}
      fontSize={16}
      fontColor="rgb(255 0 0 / 25%)"
      style={style}
      content={watermark}>
      {children}
    </Watermark>
  );
}
