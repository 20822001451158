import { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import AlertDialog from '../../../components/AlertDialog';
import { customerAPI } from '../../../apis/customerAPI';
import { UserContext } from '../../../context/UserContext';
import AlertMessage from '../../../components/AlertMessage';
import { RiskReviewContext } from '../context/RiskReviewContext';

const steps = ['KYC 審核', 'AML 審核'];

export default function HorizontalLinearStepper({
  children,
  activeStep,
  setActiveStep,
}) {
  const [showDialog, setShowDialog] = useState(false);
  const location = useLocation();
  const { state } = useLocation();
  const { setLoading } = useContext(UserContext);
  const navigate = useNavigate();
  const [alert, setAlert] = useState('');
  const riskReviewResult = useContext(RiskReviewContext);
  const [kycCanProceed, setKycCanProceed] = useState(false);
  const [amlCanProceed, setAmlCanProceed] = useState(false);

  const handleChange = () => {
    setKycCanProceed(riskReviewResult.kycReviewComment.length > 0);
    setAmlCanProceed(riskReviewResult.amlReviewComment.length > 0);
  };

  // Function to replace the last part of the path
  const replaceLastPath = (newPath) => {
    const currentPath = location.pathname;
    const newPathname =
      currentPath.substring(0, currentPath.lastIndexOf('/') + 1) + newPath;
    return newPathname;
  };

  function handleNext() {
    if (activeStep === steps.length - 1) {
      setShowDialog(true);
    } else {
      activeStep += 1;
    }

    setActiveStep(activeStep);
    const newPathname = replaceLastPath('aml');
    navigate(newPathname, { state: state, replace: true });
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const newPathname = replaceLastPath('kyc');
    navigate(newPathname, { state: state, replace: true });
  }

  async function sendRiskReview(payload) {
    setLoading(true);
    const response = await customerAPI.sendRiskReview(payload);
    setLoading(false);

    if (response?.error) {
      setAlert(response.error.message);
    } else {
      navigate('/riskReview');
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [activeStep]);

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <>
        <Box sx={{ mt: 2, mb: 1 }} onChange={handleChange}>
          {children}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            variant="contained"
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1, borderRadius: '24px', width: '150px' }}>
            上一步
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button
            variant="contained"
            disabled={
              activeStep === 0
                ? riskReviewResult.kycReviewResult !== null && !kycCanProceed
                : (riskReviewResult.amlReviewResult !== null && !amlCanProceed) ||
                  (riskReviewResult.kycReviewResult === null && riskReviewResult.amlReviewResult === null)
            }
            onClick={handleNext}
            sx={{ borderRadius: '24px', width: '150px' }}>
            {activeStep === steps.length - 1 ? '審核送出' : '下一步'}
          </Button>
        </Box>
        <AlertDialog
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          title={'是否確定送出'}
          truthyBtnText={'確定送出意見'}
          falsyBtnText={'取消'}
          handleTruthyAction={sendRiskReview}
          type="staff"
        />
      </>
      <AlertMessage alertState={{ alert, setAlert, severity: 'warning' }} />
    </Box>
  );
}
