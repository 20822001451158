import { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import CustomerStatus from './CustomerStatus';
import CustomerTrade from './CustomerTrade';
import CustomerAsset from './CustomerAsset';
import CustomerDeposit from './CustomerDeposit';
import CustomerWithdraw from './CustomerWithdraw';
import CustomerEddData from './CustomerEddData';
import CustomerBankAccount from './CustomerBankAccount';

function TabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ maxWidth: 'lg', border: 'solid 1px' }}>
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function UserDetail({ id }) {
  const [userDetaiTabIndex, setUserDetailTabIndex] = useState(0);
  const handleChange = (event, newValue) => {
    setUserDetailTabIndex(newValue);
  };

  const componentLists = [
    { title: '認證狀態', value: <CustomerStatus id={id} /> },
    { title: 'EDD資料', value: <CustomerEddData id={id} /> },
    { title: '銀行帳戶', value: <CustomerBankAccount id={id} /> },
    { title: '交易紀錄', value: <CustomerTrade id={id} /> },
    { title: '帳戶資產', value: <CustomerAsset id={id} /> },
    {
      title: '儲值紀錄',
      value: <CustomerDeposit id={id} />,
    },
    {
      title: '提領紀錄',
      value: <CustomerWithdraw id={id} />,
    },
  ];

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '10px' }}>
        <Box sx={{ marginTop: '30px' }}>
          <Tabs
            value={userDetaiTabIndex}
            onChange={handleChange}
            aria-label="basic tabs example">
            {componentLists.map((item, index) => (
              <Tab
                key={item.title}
                label={item.title}
                id={`simple-tab-${index}`}
                aria-controls={`simple-tabpanel-${index}`}
              />
            ))}
          </Tabs>
        </Box>
        <Box sx={{ marginTop: '10px' }}>
          {componentLists.map((item, index) => (
            <TabPanel value={userDetaiTabIndex} index={index} key={index}>
              {item.value}
            </TabPanel>
          ))}
        </Box>
      </Box>
    </>
  );
}
