import { useState } from 'react';
import { Box, Grid, CircularProgress } from '@mui/material';

import Panel from '../../../components/Panel';
import CustomGrid from '../../../components/CustomGrid';
import { useEffectOnce } from '../../../utils/UseEffectOnce';

import { customerAPI } from '../../../apis/customerAPI';

export default function CustomerEddData({ id }) {
  const [isFetching, setIsFetching] = useState(false);
  const [eddData, setEddData] = useState([]);

  useEffectOnce(() => {
    const fetchUserEddData = async () => {
      setIsFetching(true);

      const response = await customerAPI.getUserEddData(id);
      if (response !== null) {
        setEddData(response.edd);
      }

      setIsFetching(false);
    };
    fetchUserEddData();
  }, []);

  return (
    <Box sx={{ marginBottom: 4 }}>
      <Panel>
        {isFetching && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
        {!isFetching && (eddData.length > 0 ? (
          <CustomGrid data={eddData} underline />
        ) : (
          <Grid container justifyContent="center" alignItems="center">
            查無資料
          </Grid>
        ))}
        {/* {eddData.length > 0 ? (
          <CustomGrid data={eddData} underline />
        ) : (
          <Grid container justifyContent="center" alignItems="center">
            查無資料
          </Grid>
        )} */}
      </Panel>
    </Box>
  );
}
