import { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';

import { customerAPI } from '../../../apis/customerAPI';
import Panel from '../../../components/Panel';
import CustomGrid from '../../../components/CustomGrid';

export default function CustomerBankAccount({ id }) {
  const [isFetching, setIsFetching] = useState(false);
  const [fetchedData, setFetchedData] = useState([
    {
      title: '銀行帳戶驗證狀態',
      value: '',
    },
    {
      title: '銀行名稱',
      value: '',
    },
    {
      title: '銀行帳號',
      value: '',
    },
    {
      title: '銀行綁定申請時間',
      value: '',
    },
    {
      title: '銀行綁定完成時間',
      value: '',
    },
    {
      title: '虛擬帳號',
      value: '',
    },
    {
      title: '綁定失敗原因',
      value: '',
    },
  ]);

  useEffect(() => {
    async function getUserBankAccount() {
      setIsFetching(true);

      const response = await customerAPI.getUserBankAccount(id);

      if (response) {
        setFetchedData([
          {
            title: '銀行帳戶驗證狀態',
            value: response?.status ? response?.status : '未驗證',
          },
          {
            title: '銀行名稱',
            value:
              response.bankCode || response.bankName
                ? `${response.bankCode + response.bankName}`
                : '',
          },
          {
            title: '銀行帳號',
            value: response.accountNumber ? response.accountNumber : '',
          },
          {
            title: '銀行綁定申請時間',
            value: response.createdTime ? response.createdTime : '',
          },
          {
            title: '銀行綁定完成時間',
            value: response.succeedTime ? response.succeedTime : '',
          },
          {
            title: '虛擬帳號',
            value: response.virtualAccountNumber
              ? response.virtualAccountNumber
              : '',
          },
          {
            title: '綁定失敗原因',
            value: response.failReason ? response.failReason : '',
          },
        ]);
      }

      setIsFetching(false);
    }

    getUserBankAccount();
  }, [id]);

  return (
    <Box sx={{ marginBottom: 4 }}>
      <Panel>
        {isFetching && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
        {!isFetching && <CustomGrid data={fetchedData} underline />}
      </Panel>
    </Box>
  );
}
