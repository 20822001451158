import { React } from 'react';
import { styled } from '@mui/system';
import { Button } from '@mui/material';

const StyledButton = styled(Button)(({ theme }) => ({
  color: '#FFF',
  fontSize: '17px',
  fontWeight: 'bold',
  borderRadius: '20px',
  height: '40px',
}));

export default function RoundCornerButton({ onClick, children }) {
  return (
    <StyledButton
      variant="contained"
      fullWidth
      disableElevation
      onClick={onClick}>
      {children}
    </StyledButton>
  );
}
