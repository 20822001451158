import { useState, useContext } from 'react';
import { TitleLabel, SubTitleLabel } from '../../../components/Label';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Panel from '../../../components/Panel';
import Divider from '@mui/material/Divider';
import ReviewComment from '../../../components/ReviewComment';
import { RiskReviewContext } from '../context/RiskReviewContext';

export default function ManualReview({ type }) {
  const riskReviewResult = useContext(RiskReviewContext);
  const resultInitialState =
    type === 'kyc'
      ? riskReviewResult.kycReviewResult
      : riskReviewResult.amlReviewResult;
  const [result, setResult] = useState(resultInitialState);

  const handleResultChange = (event) => {
    setResult(event.target.value);
    if (type === 'kyc') {
      riskReviewResult.kycReviewResult = event.target.value;
    } else if (type === 'aml') {
      riskReviewResult.amlReviewResult = event.target.value;
    }
  };

  return (
    <Panel>
      <TitleLabel fontSize="18px">人工審查</TitleLabel>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}>
        <FormLabel
          id="demo-row-radio-buttons-group-label"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <SubTitleLabel fontSize="16px">審核結果</SubTitleLabel>
        </FormLabel>
        <RadioGroup
          row
          value={result}
          onChange={handleResultChange}
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group">
          <FormControlLabel
            value="staff_approved"
            control={<Radio />}
            label="通過"
          />
          <FormControlLabel
            value="staff_rejected"
            control={<Radio />}
            label="不通過"
          />
          {type === 'aml' && (
            <FormControlLabel
              value="staff_blocked"
              control={<Radio />}
              label="命中制裁名單"
            />
          )}
        </RadioGroup>
      </Box>
      <Divider sx={{ marginY: '10px' }} />
      <SubTitleLabel fontSize="16px">審查意見</SubTitleLabel>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <ReviewComment label={'上限 500 字'} type={type} />
      </Box>
    </Panel>
  );
}
