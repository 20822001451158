import { useContext, useEffect } from 'react';
import Authorizer from '../../utils/Authorizer';
import UserQueryPanel from './components/UserQueryPanel';
import { UserContext } from '../../context/UserContext';
import { Container } from '@mui/material';
export default function CustomerService() {
  const { setSubNavTitle } = useContext(UserContext);

  useEffect(() => {
    setSubNavTitle('');
  }, [setSubNavTitle]);

  return (
    <Container maxWidth="md" sx={{ marginTop: '40px' }}>
      <UserQueryPanel />
    </Container>
  );
}
